import { get } from "lodash";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { Suspense } from "react";
import Loader from "../../components/Loader";

const Patients = dynamic(() => import("../../components/Patients"), {
  ssr: false,
  loading: () => (
    <div className="flex items-center justify-center bold text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-900 w-full h-full">
      <Loader size="medium" label="Loading..." />
    </div>
  ),
});

const PatientsPage = () => {
  const router = useRouter();
  const patient_uid = Number(get(router, ["query", "params", "0"], 0));

  return (
    <Suspense fallback={`Loading...`}>
      <Patients patient_uid={patient_uid} />
    </Suspense>
  );
};

export async function getStaticProps(context) {
  return {
    // Passed to the page component as props
    props: {
      hasShare: context?.params?.length > 0 ? true : false,
    },
  };
}

export const getStaticPaths = () => {
  return {
    paths: [], //indicates that no page needs be created at build time
    fallback: "blocking", //indicates the type of fallback
  };
};

export default PatientsPage;
